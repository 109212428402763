import React from 'react'
import "./itemscard.scss"

const ItemsCard = ({ collections }) => {
    return (
        <>
            <section className="itemscard">
                {collections?.map((item, index) => {
                    return (
                        <div key={index} className="card-box">
                            <div className="main-img">
                                <img src={item?.BannerImage} alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-content">
                                <div className="left">
                                    <img src={item?.logoImage} alt="img" className='img-fluid' />
                                </div>
                                <div className="right">
                                    <h6>{item?.name}</h6>
                                    <p>Created by <span>{item?.creator?.slice(0, 5) +
                                        "..." +
                                        item?.creator?.slice(
                                            item?.creator?.length - 4,
                                            item?.creator?.length
                                        )}</span></p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </section>
        </>
    )
}

export default ItemsCard
