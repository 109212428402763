import React from 'react'
import "./collectioncard.scss"

const CollectionCard = ({ nfts }) => {
    return (
        <>
            <section className="collectioncard">
                {nfts?.map((item, index) => {
                    return (
                        <div key={index} className="card-box">
                            <div className="main-img">
                                <img src={item?.image} alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-content">
                                <div className="upper-text">
                                    <h6>{item?.nftName}</h6>
                                    <span>{item?.chain}</span>
                                </div>
                                <div className="bottom-text">
                                    <div className="left">
                                        <div className="profile">
                                            {/* <img src="\myprofile\dummycard.png" alt="img" className='img-fluid' /> */}
                                            <img src={item?.creators[0]?.picture} alt="Axies" className="verified___badge" />
                                            {
                                                item?.verified ?
                                                    <div className="badge"><i className="ripple"></i></div>
                                                    : null
                                            }
                                        </div>
                                        <div className="right-inner">
                                            <p>Creator</p>
                                            <h6>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}</h6>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p>Last Price</p>
                                        <h6>{item?.lastPrice}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </section>
        </>
    )
}

export default CollectionCard
