import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import './document.scss'

const Privacypolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <section className='privacy'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 p-0'>
                        <div className='privacymain'>
                            <h4 className='head mb-5'>PRIVACY POLICY</h4>
                            <p className='para mb-4'>This privacy policy (“Policy”) describes how Bluemoon (“we”, “us”, or “our”) collects, uses, and protects the personal information of users of our Metaverse app (the “App”).</p>
                            <p className='para mb-4'>By using the App, you agree to the collection, use, and sharing of your personal information as described in this Policy. If you do not agree with our policies and practices, do not use the App.</p>
                            <p className='parahead mb-4'>COLLECTION OF PERSONAL INFORMATION</p>
                            <p className='para mb-5'>We collect personal information from you when you use the App, including:</p>
                            <ul className='mb-5'>
                                <li>
                                    <p className='para'>Your name and email address when you create an account</p>
                                </li>
                                <li>
                                    <p className='para'>Your IP address and device information when you access the App</p>
                                </li>
                                <li>
                                    <p className='para'>Any personal information you choose to include in your profile or content you post within the App</p>
                                </li>
                            </ul>
                            <p className='para mb-4'>We may also collect personal information from third-party sources, such as social media platforms, to enhance your user experience and personalize the content we provide to you.</p>
                            <p className='parahead mb-4'>USE OF PERSONAL INFORMATION</p>
                            <p className='para mb-5'>We use the personal information we collect from you for the following purposes:</p>
                            <ul className='mb-5'>
                                <li>
                                    <p className='para'>To provide and maintain the App</p>
                                </li>
                                <li>
                                    <p className='para'>To communicate with you about your use of the App</p>
                                </li>
                                <li>
                                    <p className='para'>To personalize the content and experiences we provide to you within the App</p>
                                </li>
                                <li>
                                    <p className='para'>For marketing and advertising purposes, including to send you promotional materials and personalized advertisements</p>
                                </li>
                            </ul>
                            <p className='parahead'>PROTECTION OF PERSONAL INFORMATION</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </>
  )
}

export default Privacypolicy