import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'

const Termsofservice = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <section className='privacy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 p-0'>
                            <div className='privacymain'>
                                <h4 className='head mb-5'>TERMS OF SERVICE</h4>
                                <p className='para mb-5'>Welcome to our Bluemoon Metaverse app (the “App”). By accessing or using the App, you agree to be bound by these terms of service (“Terms”). If you do not agree to all the Terms, then you may not access or use the App.</p>
                                <p className='parahead mb-5'>USE OF THE APP</p>
                                <ul className='mb-5'>
                                    <li>
                                        <p>You must be at least 13 years old to use the App. If you are under 13, you may not use the App.</p>
                                    </li>
                                    <li>
                                        <p>You may not use the App for any illegal or unauthorized purpose.</p>
                                    </li>
                                    <li>
                                        <p>You may not use the App to transmit any viruses or malicious code.</p>
                                    </li>
                                    <li>
                                        <p>You may not use the App to harass, abuse, or harm others.</p>
                                    </li>
                                    <li>
                                        <p>You may not use the App to infringe upon the intellectual property rights of others.</p>
                                    </li>
                                </ul>
                                <p className='parahead mb-5'>ACCOUNT</p>
                                <ul className='mb-5'>
                                    <li>
                                        <p>You must create an account to use certain features of the App.</p>
                                    </li>
                                    <li>
                                        <p>You are responsible for maintaining the confidentiality of your account and password.</p>
                                    </li>
                                    <li>
                                        <p>You are responsible for all activities that occur under your account.</p>
                                    </li>
                                    <li>
                                        <p>You may not transfer your account to another person.</p>
                                    </li>
                                </ul>
                                <p className='parahead mb-5'>CONTENT</p>
                                <ul className='mb-5'>
                                    <li>
                                        <p>You are solely responsible for the content you post within the App, including any text, images,or other materials.</p>
                                    </li>
                                    <li>
                                        <p>You may not post any content that is illegal, obscene, defamatory, or that infringes upon the intellectual property rights of others.</p>
                                    </li>
                                    <li>
                                        <p>We reserve the right to remove any content that violates these Terms or that we determine to be inappropriate for the App.</p>
                                    </li>
                                </ul>
                                <p className='parahead'>INTELLECTUAL PROPERTY</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default Termsofservice