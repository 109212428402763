import React, { Suspense, useEffect } from 'react';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Join from './components/Waitjoin/Join';
// import AllModal from './components/landing/Modals/AllModal';
// import Footer from './components/Footer/Footer';
// import Planet from './components/planet/Planet';
// import Congratulations from './components/landing/Modals/Congratulations';
// import Error from './components/landing/Modals/Error';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Translator } from 'react-auto-translate';
import { useState } from 'react';
import Loader from './hooks/loader';
import Termsofservice from './components/Documents/Termsofservice';
import Privacypolicy from './components/Documents/Privacypolicy';
import Myprofile from './components/MyProfile/Myprofile';
import Editprofile from './components/EditProfile/Editprofile';
import UserFollowCard from './components/UserFollowCard/UserFollowCard';
import CollectionCard from './components/CollectionCard/CollectionCard';
import ItemsCard from './components/ItemsCard/ItemsCard';

const Verification = React?.lazy(() => import('./components/Verification/Verification'));
const Login = React?.lazy(() => import('./components/Login/login'));
const Checkotp = React?.lazy(() => import('./components/Verification/Checkotp'));
const ResetPass = React?.lazy(() => import('./components/ResPass/ResetPass'));
const Verified = React?.lazy(() => import('./components/Verification/Verified'));
// const Loader = React?.lazy(() => import('./hooks/loader'))
const Landing = React?.lazy(() => import('./components/landing/Landing.js'));
const Seceamilform = React?.lazy(() => import('./components/moonemail/Seceamilform'));
const Navbar = React?.lazy(() => import('./components/landing/header/Navbar.js'));
const Join = React?.lazy(() => import('./components/Waitjoin/Join'));
const AllModal = React?.lazy(() => import('./components/landing/Modals/AllModal'));
const Footer = React?.lazy(() => import('./components/Footer/Footer'));
const Congratulations = React?.lazy(() => import('./components/landing/Modals/Congratulations'));
const Error = React?.lazy(() => import('./components/landing/Modals/Error'));


function App() {
  // useEagerConnect()
  // const [getLocal, getLocalStorage] = useState('es');
  // const [toLanguage, setToLanguage] = useState(getLocal);
  // console.log('this is local vs stg',getLocal,toLanguage)

  const [toLanguage, setToLanguage] = useState('en');
  // useEffect(() => {
  //   let url = window.location.href;
  //   if (url.includes("https://waitlist.bluemoon.io")) {
  //     window.open("https://bluemoon.io/", "_self")
  //   }
  // }, [])
  // useEffect(()=>(
  //   getLocalStorage(localStorage.getItem("defaultLang"))
  // ,[getLocal]))

  return (
    <Suspense fallback={<Loader />}>
      <Translator
        from="en"
        to={toLanguage}
        googleApiKey="AIzaSyCSdICUCQ9ssgjnpGDaKubeDotdHzrdegE"
      >
        <ToastContainer style={{ fontSize: 20 }} />
        <Router>
          <Navbar setToLanguage={setToLanguage} />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/leaderboard" component={Seceamilform} />
            <Route exact path="/join" component={Join} />
            <Route exact path="/Modal" component={AllModal} />
            <Route exact path="/footer" component={Footer} />
            <Route exact path="/congratulation" component={Congratulations} />
            <Route exact path="/error" component={Error} />
            <Route exact path="/verification" component={Verification} />
            <Route exact path="/checkotp" component={Checkotp} />
            <Route exact path="/verified" component={Verified} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset_password" component={ResetPass} />
            <Route exact path="/Termsofservice" component={Termsofservice} />
            <Route exact path="/Privacypolicy" component={Privacypolicy} />
            <Route exact path="/profile" component={Myprofile} />
            <Route exact path="/editprofile" component={Editprofile} />
            <Route exact path="/followcards" component={UserFollowCard} />
            <Route exact path="/collectioncard" component={CollectionCard} />
            <Route exact path="/itemscard" component={ItemsCard} />
          </Switch>
        </Router>
      </Translator>
    </Suspense>
  );
}

export default App;
