import React, { useState, useEffect } from 'react'
import "./myprofile.scss"
import Tab from 'react-bootstrap/Tab';
import dummyImg from "../../Assets/dummyImg.png";
import Tabs from 'react-bootstrap/Tabs';
import { Api_URL } from '../../utils/ApiURL';
import dummyProfile from "../../Assets/dummyProfile.png";
import { Link } from 'react-router-dom';
import CollectionCard from '../CollectionCard/CollectionCard'
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from '../../hooks/loader';
import axios from 'axios';
import ItemsCard from '../ItemsCard/ItemsCard';
import UserFollowCard from '../UserFollowCard/UserFollowCard';

const Myprofile = () => {
    const [data, setData] = useState(null);
    const [copied, setCopied] = useState(false);
    const [loader, setLoader] = useState(false);
    const [onsale, setOnsale] = useState([]);
    const [ownedCollection, setOwnedCollection] = useState([]);
    const [collectionByCreatorAddress, setCollectionByCreatorAddress] = useState(null);
    const [createdCollection, setCreatedCollection] = useState([]);
    const [followers, getFollowers] = useState();
    const [following, getFollowing] = useState();

    useEffect(() => {
        getUser();
        getOwnedNft();
        getCollectionByUser();
        getCreatedNft();
        getFollowersDetails();
        getFollowingDetails();
    }, []);

    const getFollowingDetails = async () => {
        setLoader(true);
        let token = localStorage.getItem("accessToken");
        await axios
            .post(
                `${Api_URL}/user/getFollowingAndDetails`,
                {
                    walletAddress: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
                },
                {
                    Authorization: `Bearer ${token}`
                }
            )
            .then((res) => {
                let data2 = res?.data?.data?.detailsOfFollowing;
                getFollowing(data2);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                console.log("getApi error", err);
            });
    };

    const getCreatedNft = async () => {
        setLoader(true);
        await axios
            .post(
                `${Api_URL}/token/getCreatedTokensOfUserAndDetails`,
                {
                    walletAddress: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
                    chainId: "5"
                }
            )
            .then((res) => {
                setLoader(false);
                setCreatedCollection(res?.data?.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log("getApi error", err);
            });
    };

    const getFollowersDetails = async () => {
        setLoader(true);
        let token = localStorage.getItem("accessToken");
        await axios
            .post(
                `${Api_URL}/user/getFollowersAndDetails`,
                {
                    walletAddress: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
                },
                {
                    Authorization: `Bearer ${token}`
                }
            )
            .then((res) => {
                let data1 = res?.data?.data.detailsOfFollowers;
                getFollowers(data1);
                var dumArrey = [];
                for (let i of data1) {
                    dumArrey.push(i.walletAddress);
                }
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                console.log("getApi error", err);
            });
    };

    const getCollectionByUser = async () => {
        let token = localStorage.getItem("accessToken");
        const data = {
            creator: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A".toLowerCase()
            //   creator: account?.toLowerCase()?.toString()
        }
        await axios
            .post(`${Api_URL}/collection/getCollectionByCreatorAddress`, data, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then((res) => {
                setCollectionByCreatorAddress(res?.data?.result);
            })
            .catch((err) => {
                toast.error(err?.response?.data.msg, {
                    position: "top-right",
                    autoClose: 2000
                });
                console?.log(err?.response?.data.msg)
            })
    }

    const getOwnedNft = async () => {
        var axios = require("axios");
        let token = localStorage.getItem("accessToken");
        setLoader(true);
        var data = JSON.stringify({
            walletAddress: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
            chainId: "5"
        });
        var config = {
            method: "post",
            url: `${Api_URL}/token/getOwnedTokensOfUserAndDetails`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json"
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setOwnedCollection(response?.data?.data);
                let dumArr = response?.data?.data?.filter((i) => {
                    return i?.metaData && i?.onSale;
                })
                setOnsale(dumArr);
                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
                console.log("getApi error", error);
            });
    };

    const getUser = async () => {
        let data = null;
        let account = localStorage.getItem("account");
        let token = localStorage.getItem("accessToken");
        // if (id) {
        //     data = { walletAddress: id }
        // } else {
        data = { walletAddress: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A" }
        // }
        await axios
            .post(`${Api_URL}/user/getUser`, data, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then((response) => {
                // let find = response?.data?.user?.followers?.find((r) => {
                //     return r.address?.toLowerCase() === account?.toLowerCase()
                // })
                // if (find) {
                //     setFollow(true);
                // }
                setData(response.data);
            })
            .catch((err) => {
                toast.error(err?.response?.data.msg, {
                    position: "top-right",
                    autoClose: 2000
                });
                console?.log(err?.response?.data.msg)
            })
    }

    const copiedHandle = () => {
        setCopied(true);
        setTimeout(
            function () {
                setCopied(false);
            }.bind(this),
            1500
        );
    };


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? "copied" : "copy"}
        </Tooltip>
    );

    return (
        <>
            {loader && <Loader />}
            <section className="myprofile">
                <div className="bg-cover">
                    <img src={data?.user?.coverPicture ? data?.user?.coverPicture : dummyImg} alt="img" className='img-fluid bg-cover-img' />
                    <div className="custom-container">
                        <div className="parent-items">
                            <div className="profile-img">
                                <img style={{ border: '10px solid white', borderRadius: 30 }} src={data?.user?.picture ? data?.user?.picture : dummyProfile} alt="img" className='img-fluid profile-img' />
                                <h6>{data?.user?.userFullName}</h6>
                                <p>{data?.user?.userEmail}</p>
                                <Link to="/editprofile"><button className='edit-btn'>Edit Profile</button></Link>
                                <div className="followlist">
                                    <h6>{data?.user?.followersCount > 0 ? data?.user?.followersCount : 0} <span>Followers</span> {data?.user?.followingCount > 0 ? data?.user?.followingCount : 0} <span>Following</span></h6>
                                </div>
                                <p className='description'>{data?.user?.bio}</p>
                                <div className="address-wallet">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                        className="copycoopppyy"
                                    >
                                        <CopyToClipboard
                                            text={data?.user?.walletAddress}
                                            onCopy={() => copiedHandle()}
                                        >
                                            <div className="copycoopppyy curp d-flex align-items-center cursor-pointer">
                                                <p>{data?.user?.walletAddress}</p>
                                                <img src="\myprofile\copy.svg" alt="img" className='img-fluid ml-2' />
                                            </div>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="social-icons">
                                {/* <a href=""><img src="\myprofile\telegram.svg" alt="img" className='img-fluid' /></a>
                                <a href=""><img src="\myprofile\discord.svg" alt="img" className='img-fluid' /></a>
                                <a href=""><img src="\myprofile\twitter.svg" alt="img" className='img-fluid' /></a> */}
                                {
                                    data?.user?.telegramLink && data?.user?.telegramLink !== "undefined" ?
                                        <a className="list-inline-item iconbtnnnn" href={"https://www.telegram.com/" + data?.user?.telegramLink} target="_blank">
                                            <img src="\myprofile\telegram.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                                {
                                    data?.user?.redditLink && data?.user?.redditLink !== "undefined" ?
                                        <a className="list-inline-item iconbtnnnn" href={"https://www.reddit.com/" + data?.user?.redditLink} target="_blank">
                                            <img src="\myprofile\reddit.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                                {
                                    data?.user?.discordLink && data?.user?.discordLink !== "undefined" ?

                                        <a className="list-inline-item iconbtnnnn" href={"https://www.discord.gg/" + data?.user?.discordLink} target="_blank">
                                            <img src="\myprofile\discord.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                                {
                                    data?.user?.twitterUserName && data?.user?.twitterUserName !== "undefined" ?

                                        <a className="list-inline-item iconbtnnnn" href={"https://www.twitter.com/" + data?.user?.twitterUserName} target="_blank">
                                            <img src="\myprofile\twitter.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                                {
                                    data?.user?.instagramLink && data?.user?.instagramLink !== "undefined" ?

                                        <a className="list-inline-item iconbtnnnn" href={"https://www.instagram.com/" + data?.user?.instagramLink} target="_blank">
                                            <img src="\myprofile\instagram.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                                {
                                    data?.user?.meduimLink && data?.user?.meduimLink !== "undefined" ?

                                        <a className="list-inline-item iconbtnnnn" href={"https://www.medium.com/" + data?.user?.meduimLink} target="_blank">
                                            <img src="\myprofile\medium.svg" alt="img" className='' />
                                        </a>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-container">
                    <div className="bottom-tabs">
                        <Tabs
                            defaultActiveKey="onsale"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="onsale" title="On Sale">
                                {onsale?.length > 0 ?
                                    <div className="onsale-content">
                                        <CollectionCard nfts={onsale} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Items to Display</h6>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey="owned" title="Owned">
                                {ownedCollection?.length > 0 ?
                                    <div className="owned-content">
                                        <CollectionCard nfts={ownedCollection} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Items to Display</h6>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey="created" title="Created">
                                {createdCollection?.length > 0 ?
                                    <div className="created-content">
                                        <CollectionCard nfts={createdCollection} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Items to Display</h6>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey="collections" title="Collections">
                                {/* <div className="collection-content">
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Items to Display</h6>
                                    </div>
                                </div> */}
                                {collectionByCreatorAddress?.length > 0 ?
                                    <div className="created-content">
                                        <ItemsCard collections={collectionByCreatorAddress} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Collections to Display</h6>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey="followers" title="Followers">
                                {followers?.length > 0 ?
                                    <div className="created-content">
                                        <UserFollowCard users={followers} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Users to Display</h6>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey="following" title="Following">
                                {following?.length > 0 ?
                                    <div className="created-content">
                                        <UserFollowCard users={following} />
                                    </div>
                                    :
                                    <div className="noitems">
                                        <img src="\myprofile\noitemtodisplay.png" alt="img" className='img-fluid' />
                                        <h6>No Users to Display</h6>
                                    </div>
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Myprofile
