import React from 'react'
import dummyImg from "../../Assets/dummyImg.png";
import "./userfollowcard.scss"

const UserFollowCard = ({ users }) => {
    return (
        <>
            <div className="wrapper-follow">
                <section className='follow-followingcards'>
                    {users?.map((item, index) => {
                        return (
                            <div key={index} className="followcard">
                                <div className="user-img">
                                    <img src={
                                        item?.picture
                                            ? item?.picture
                                            : dummyImg
                                    } alt="img" className='img-fluid' />
                                </div>
                                <h6 className='title'>{item?.userFullName ? item?.userFullName : item?.walletAddress?.slice(0, 12) + "..."}</h6>
                                {/* <button className='follow-btn'>Follow</button> */}
                            </div>
                        )
                    })}
                    {/* <div className="followcard">
                        <div className="user-img">
                            <img src="\myprofile\profile.png" alt="img" className='img-fluid' />
                        </div>
                        <h6 className='title'>Crispin Berry</h6>
                        <button className='unfollow-btn'>UnFollow</button>
                    </div> */}
                </section>
                {/* <button className='btn-load'>Load More</button> */}
            </div>
        </>
    )
}

export default UserFollowCard
