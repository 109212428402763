import React from 'react';
import "./editprofile.scss";
import "./editprofile.scss";
import dummyImg from "../../Assets/dummyImg.png";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { API_URL } from "../../utils/ApiURL";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
// import * as Yup from 'yup';
import Loader from '../../hooks/loader';

const Editprofile = () => {
  return (
    <>

    <section className="editprofile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-10 col-12 m-auto p-0">
            <div className="heading">
              <h2>Edit Your Profile</h2>
              <p>You can set preferred display name and manage other personal settings</p>
            </div>
            <div className="row">
              <div className="col-xl-6 col-12 padd-sm order-cahnge1">
                <div className="input-field">
                  <h6>Display Name</h6>
                  <input type="text" placeholder='Enter your display name'/>
                </div>
                <div className="input-field">
                  <h6>Bio</h6>
                 <textarea placeholder='Tell about yourself in a few words'/>
                </div>
                <div className="input-field">
                  <h6>Twitter</h6>
                  <input type="text" placeholder='Connect your twitter account' className='specific'/>
                  <button className="connect">Connect</button>
                </div>
                <div className="input-field">
                  <h6>Discord</h6>
                  <input type="text" placeholder='Connect your discord account'/>
                </div>
                <div className="input-field">
                  <h6>Telegram</h6>
                  <input type="text" placeholder='Connect your telegram account'/>
                </div>
                <div className="input-field">
                  <h6>Email</h6>
                  <input type="text" placeholder='Enter your email address'/>
                  <button className="connect">Connect</button>
                </div>
                <div className="input-field">
                  <h6>Wallet Address</h6>
                  <input type="text" placeholder='0x1B92ABFC520D4210EC3D6EB5173988C6AE066231'/>
                  <button className="connect">Copy</button>
                </div>
                <div className="bottom-btn">
                  <button>Update Profile</button>
                </div>
              </div>
              <div className="col-xl-6 col-12 padd-sm order-cahnge">
                <div className="top">
                <h4>Profile Picture</h4>
                <div className="profile">
                  <div className="profile-img">
                    <img src="\myprofile\profile.png" alt="img" className='img-fluid' />
                  </div>
                  <div className="profile-data">
                    <p>We recommend an image of at least 300x300. Gifs work too. Max 5mb.</p>
                  <label htmlFor="upload" className='upload'>Upload Profile</label>
                  <input type="file" className='d-none' id='upload' />
                  </div>
                </div>
                </div>
                <div className="bottom">
                  <h4>over Photo</h4>
                  <div className="cover-img">
                    <img src="\myprofile\profile.png" alt="img" className='img-fluid' />
                  </div>
                  <label htmlFor='uploadcover' className='upload'>Upload Cover</label>
                  <input type="file" className='d-none' id='uploadcover' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Editprofile;