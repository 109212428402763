import React from 'react';
import ReactDOM from 'react-dom';
import { renderToPipeableStream } from 'react-dom/server';
import './index.css';
import App from './App';

// const { pipe } = renderToPipeableStream(<App />);


ReactDOM.render(
      <App />,
  document.getElementById('root')
);

