import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import "./footer.scss";
import { Translator, Translate } from 'react-auto-translate';
import Insta from '../../Assets/Instagram_black.svg';
import LinkedIn from '../../Assets/LinkedIN_black.svg';
import twitter from '../../Assets/Twitter_black.svg';
import FB from '../../Assets/Facebook_black.svg';


const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div>
            <footer id="footer" className="footer-light-style clearfix bg-style">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-10 m-auto">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12 p-0 m-auto text-left">
                                    <div className="widget widget-logo">
                                        <div className="logo-footer" id="logo-footer">
                                            <Link to="">
                                                <img loading="lazy" className='logo-dark wh-100' id="logo_footer" src="\assestwait\logo-svg.webp" alt="nft-gaming" style={{ maxWidth: "192px", height: "31.1px" }} />
                                            </Link>
                                        </div>
                                        <div className='socialicons '>
                                            <a href='https://www.instagram.com/bluemoon.io_/' target='_blank'> <img src={Insta} alt='Insta' /></a>
                                           <a href='https://www.linkedin.com/showcase/bluemoon-metaverse/' target='_blank'> <img src={LinkedIn} alt='LinkedIn' /></a>
                                           <a href='https://twitter.com/Bluemoon_io' target='_blank'> <img src={twitter} alt='twitter' /></a>
                                           <a href='https://www.facebook.com/bluemoonnft/' target='_blank'>   <img src={FB} alt='FB' /></a>
                                         
                                        </div>

                                        {/* <h2 className="sub-widget-logo"><Translate>The First NFT Marketplace with an </Translate><br /><Translate> integrated Metaverse</Translate></h2> */}
                                    </div>
                                    {/* <div className='d-flex justify-content-between align-items-center'>
                                        <div className="header_Social_wrapper header_Social_wrapper__desktop d-flex align-items-center justify-content-start">
                                            <div className="Header_social_Icon">
                                                <a href="http://discord.gg/legionnetwork" target="_blank">
                                                    <img loading="lazy" src="\discortSVG.svg" />
                                                </a>
                                            </div>
                                            <div className="Header_social_Icon">
                                                <a href="https://twitter.com/Bluemoon_io" target="_blank">
                                                    <img loading="lazy" src="\twitterSVG.svg" />
                                                </a>
                                            </div>

                                            <a className="m-0" href="https://www.instagram.com/bluemoon.io_/" target="_blank">
                                                <div className="Header_social_Icon p-2 mr-0">
                                                    <img loading="lazy" src="\assestwait\insta-new.svg" alt="img" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-4 mb-2 justify-content-start forcenter'>
                                        <Link to="/termsofservice">
                                            <p className='text-white text-center'>Terms of Service</p>
                                        </Link>
                                        <Link to="/privacypolicy">
                                            <p className='ml-3 text-white text-center'>Privacy Policy</p>
                                        </Link>
                                    </div> */}
                                </div>
                                <div className='footer-links-new col-lg-6 col-md-12 col-12 p-0 ml-auto'>
                                    <div className='parent'>
                                        <div className='left'>
                                            <a className='para' href="/contacts">
                                                Access Metaverse
                                            </a>
                                            <a className='para' href="/contacts">
                                                Host an Even
                                            </a>
                                            <a className='para' href="/contacts">
                                                Custom Space
                                            </a>
                                            <a className='para' href="/pricing">
                                                Pricing
                                            </a>
                                            <a className='para' href="/casestudies">
                                                Case Studies
                                            </a>
                                        </div>
                                        <div className='left'>
                                            <a className='para' href="/about">
                                                About
                                            </a>
                                            <a className='para' href="/careers">
                                                Careers
                                            </a>
                                            <a className='para' href="/news">
                                                News
                                            </a>
                                            <a className='para' href="/">
                                                Documentation
                                            </a>
                                            <a className='para' href="/contacts">
                                                Contact Us
                                            </a>
                                        </div>
                                    </div>
                                    {/* <ul>
                                        <li>
                                        <Link to={'/'}>
                                        Access Metaverse
                                    </Link>
                                        </li>
                                        <li>

                                        <Link to={'/'}>Host an Event</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>Custom Space</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>Pricing</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>Case Studies</Link>
                                        </li>
                                    </ul> */}


                                </div>
                                {/* <div className='footer-links-new col-lg-3 col-md-12 col-12 p-0 ml-auto'>
                                    <ul>
                                        <li>
                                        <Link to={'/'}>
                                        About
                                    </Link>
                                        </li>
                                        <li>

                                        <Link to={'/'}>Careers</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>News</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>Documentation</Link>
                                        </li>
                                        <li>
                                        <Link to={'/'}>Contact Us</Link>
                                        </li>
                                    </ul>
                                  
                                  
                                </div> */}
                                {/* <div className="header_Social_wrapper header_Social_wrapper__mobile d-flex align-items-center">
                            <div className="Header_social_Icon">
                                <img loading="lazy" src="\discortSVG.svg" />
                            </div>
                            <div className="Header_social_Icon">
                                <img loading="lazy" src="\twitterSVG.svg" />
                            </div>
                            <div className="Header_social_Icon p-2">
                                <img loading="lazy" src="\assestwait\insta-new.svg" />
                            </div>
                        </div> */}
                                {/* <div className="col-lg-2 col-md-4 col-sm-5 col-5 padd-left-md">
                            <div className="widget widget-menu style-1">
                                <h5 className="title-widget"><Translate>My Account</Translate></h5>
                                <ul className='anchor'>
                                    {
                                        accountList.map((item, index) => (
                                            <li key={index}><Link to={item.link}><Translate>{item.title}</Translate></Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-7 col-7">
                            <div className="widget widget-menu style-2">
                                <h5 className="title-widget"><Translate>Resources</Translate></h5>
                                <ul className='anchor'>
                                    {
                                        resourcesList.map((item, index) => (
                                            <li key={index}><Link to={item.link}><Translate>{item.title}</Translate></Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-5 col-5 padd-left-md">
                            <div className="widget widget-menu fl-st-3">
                                <h5 className="title-widget"><Translate>Company</Translate></h5>
                                <ul className='anchor'>
                                    {
                                        companyList.map((item, index) => (
                                            <li key={index}><Link to={item.link}><Translate>{item.title}</Translate></Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div> */}
                                {/* <div className="col-lg-6 col-md-6 col-sm-7 col-12 padd-left-md text-right uycucuctuc">
                                    <div className="widget widget-subcribe mb-4">
                                        <h5 className="title-widget"><Translate>Partner With Us:</Translate></h5>
                                        <div className="form-subcribe">
                                            <a className='mail-text' href="mailto:hello@bluemoon.io">
                                            <svg style={{width: "32px", height: "32px"}} className='mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="32" height="32" fill="none"/><polyline points="224 56 128 144 32 56" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><path d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="110.5" y1="128" x2="34.5" y2="197.7" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="221.5" y1="197.7" x2="145.5" y2="128" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>
                                                hello@bluemoon.io</a>
                                            <form id="subscribe-form" action="#" method="GET" acceptCharset="utf-8" className="form-submit">
                                        <input name="email" className="email" type="email" placeholder="info@yourgmail.com" required />
                                        <a id="submit" name="submit" type="submit"><img loading="lazy" src='\assestwait\Group199.svg' className='img-fluid' /></a>
                                    </form>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <hr className='divider-line' />


                            <div className=' row '>
                                <div className=' col-lg-12 col-md-12 col-12 p-0 m-auto'>
                                    <div className='copyright-wrapper'>
                                        <div className='copy-col'>
                                            <div className='footer-copy-links'>
                                                <p>© 2023 Bluemoon. All rights reserved.</p>
                                                <Link to={'/'}>Privacy Policy</Link>
                                                <Link to="/">Terms of Service</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 p-0 footer-links-new social-links'>

                                </div>
                            </div>






                            <div className="col-lg-3 col-md-12 col-12 uteww d-block d-sm-none d-lg-none d-xl-none d-md-none p-0">
                                <div className="header_Social_wrapper header_Social_wrapper__desktop d-flex align-items-center justify-content-start mobile-icons">
                                    <div className="Header_social_Icon">
                                        <a href="http://discord.gg/legionnetwork" target="_blank">
                                            <img loading="lazy" src="\discortSVG.svg" />
                                        </a>
                                    </div>
                                    <div className="Header_social_Icon">
                                        <a href="https://twitter.com/Bluemoon_io" target="_blank">
                                            <img loading="lazy" src="\twitterSVG.svg" />
                                        </a>
                                    </div>

                                    <a className="m-0" href="https://www.instagram.com/bluemoon.io_/" target="_blank">
                                        <div className="Header_social_Icon p-2 mr-0">
                                            <img loading="lazy" src="\assestwait\insta-new.svg" alt="img" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
            }
            <div className="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body space-y-20 pd-40">
                            <h3>Place a Bid</h3>
                            <p className="text-center">You must bid at least <span className="price color-popup">4.89 ETH</span>
                            </p>
                            <input type="text" className="form-control"
                                placeholder="00.00 ETH" />
                            <p>Enter quantity. <span className="color-popup">5 available</span>
                            </p>
                            <input type="number" className="form-control" placeholder="1" />
                            <div className="hr"></div>
                            <div className="d-flex justify-content-between">
                                <p> You must bid at least:</p>
                                <p className="text-right price color-popup"> 4.89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Service free:</p>
                                <p className="text-right price color-popup"> 0,89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Total bid amount:</p>
                                <p className="text-right price color-popup"> 4 ETH </p>
                            </div>
                            <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;